<template>
  <div style="padding: 15px;background-color: #fff;">
    <div class="cop_title">
      <svg t="1728612191932" @click="CompareShow" style="margin-right: 1.4285714285714286rem" class="icon" viewBox="0 0 1024 1024"
        version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9222" width="1.7142857142857142rem" height="1.7142857142857142rem">
        <path
          d="M879.476364 470.341818H244.829091L507.112727 209.454545a41.658182 41.658182 0 0 0-58.88-58.88L114.967273 482.676364a41.890909 41.890909 0 0 0 0 58.88l333.265454 333.032727a41.658182 41.658182 0 0 0 58.88 0 41.890909 41.890909 0 0 0 0-58.88L244.829091 553.658182h634.647273a41.658182 41.658182 0 1 0 0-83.316364z"
          p-id="9223" fill="#1290C9"></path>
      </svg>
      <!-- <img src="../../assets/img/detail/title_ic17.png" /> -->
      Compare Companies
    </div>

    <div class="cop_cont_box">
      <!-- <div class="cop_cont_head flex_box">
          <div class="cop_companyfirst"></div>
          <div class="cop_company flex_center">Company</div>
        
       
        </div> -->
      <div class="cop_cont_cont flex_box">
        <div class="list title list-title contentone" style="border-right: 1px solid #e7eaed;">
          <div class="item item_active">Company Name:</div>
          <div class="item">Company Native Name:</div>
          <div class="item">Legal Status:</div>
          <div class="item">Legal Form:</div>
          <div class="item">Years in Business:</div>
          <div class="item">Registered Capital:</div>
          <div class="item">ISIC:</div>
          <div class="item">Industry Description:</div>
          <div class="item">Listed:</div>
          <div class="item">No.of Employees:</div>
          <div class="item">No.of Shareholders:</div>
          <div class="item">Revenue:</div>
          <div class="item">Net Income:</div>
          <div class="item">Total Assets:</div>
          <div class="item">Profit margin:</div>
          <div class="item">Profitability:</div>
          <div class="item">Compliance Risk:</div>
          <div class="item">Local Credit Score:</div>
          <!-- <div class="item" v-show="lastView || lastView3"></div> -->
        </div>
        <div class="list content" style="border-right: 1px solid #e7eaed;">
          <div class="item item_active">
            <div class="Deletepostionleft" style="background-color: #1290C9;"><svg style="margin:0px 10px;" t="1730875056113" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5985" width="20" height="20"><path d="M990.73312 306.653c-2.624-2.624-254.57-254.32-279.19-278.44-0.875-0.875-1.625-1.625-2.625-2.375-45.74-37.242-111.6-33.868-153.217 7.874-35.242 35.117-33.243 82.232-31.993 112.85 0.125 7.874 0.375 15.997 0.375 23.495 0 32.618-30.744 79.858-78.233 120.35-49.615 42.366-106.728 68.235-139.096 62.986-7.623-1.25-14.622-1.874-20.87-2.374-3-0.25-5.624-0.5-8.248-0.75-152.718-37.867-221.453 62.362-229.451 74.984-11.623 20.246-8.499 45.74 7.873 61.987l166.964 166.964-212.97 288.313c-15.122 20.246-12.997 48.115 5.124 66.361 9.998 9.998 22.995 15.122 35.992 15.122 10.498 0 20.996-3.25 29.869-9.998L372.35012 803.547l164.964 164.965c16.622 16.621 42.366 19.746 62.987 7.248 11.498-7.248 111.476-75.484 74.61-225.077-0.376-2.5-2.5-20.62-3.625-30.868-2.625-31.993 28.619-103.353 79.358-158.591 35.992-39.117 72.86-60.737 103.728-60.737 7.498 0 15.621 0.125 22.995 0.375 31.243 1.374 78.483 3.374 113.6-31.743 21.62-21.62 33.618-50.365 33.493-81.108-0.109-30.744-11.997-59.612-33.727-81.358z" fill="#FFFFFF" p-id="5986"></path></svg> Target Company</div>
            <div class="line_2 " style="font-family: 'Arial Bold';">
              {{ compObj.CompanyName }}
            </div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.CompanyNativeName }}</div>
          </div>
          <div class="item">
            <div class="line_2">
              <div class="line_2">{{ compObj.status }}</div>
              <!-- <div class="c_d_tag">
                                  <el-tag v-show="!showTranslate" v-for="(item, index) in compObj.status" :key="index + item">{{ item }}</el-tag>
                                  <el-tag v-show="showTranslate" v-for="(item, index) in compObj.status" :key="index">{{ item}}</el-tag>
                              </div> -->
            </div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.LegalForm }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.YearsinBusiness }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.RegCapital }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.isic }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.IndustryDescription }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.Listed }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.NoofEmployees }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.Noofshareholders }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.Revenue }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.Netincome }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.TotalAssets }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.Profitmargin }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.Profitability }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.BlackList }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj.CreditScore }}</div>
          </div>
          <!-- <div class="item" v-show="lastView || lastView3"></div> -->
        </div>
        <div class="list content" style="border-right: 1px solid #e7eaed;">
          <div class="item item_active">
       
              <div class="Deletepostionleft"><svg style="margin:0px 10px;" t="1730875056113" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5985" width="20" height="20"><path d="M990.73312 306.653c-2.624-2.624-254.57-254.32-279.19-278.44-0.875-0.875-1.625-1.625-2.625-2.375-45.74-37.242-111.6-33.868-153.217 7.874-35.242 35.117-33.243 82.232-31.993 112.85 0.125 7.874 0.375 15.997 0.375 23.495 0 32.618-30.744 79.858-78.233 120.35-49.615 42.366-106.728 68.235-139.096 62.986-7.623-1.25-14.622-1.874-20.87-2.374-3-0.25-5.624-0.5-8.248-0.75-152.718-37.867-221.453 62.362-229.451 74.984-11.623 20.246-8.499 45.74 7.873 61.987l166.964 166.964-212.97 288.313c-15.122 20.246-12.997 48.115 5.124 66.361 9.998 9.998 22.995 15.122 35.992 15.122 10.498 0 20.996-3.25 29.869-9.998L372.35012 803.547l164.964 164.965c16.622 16.621 42.366 19.746 62.987 7.248 11.498-7.248 111.476-75.484 74.61-225.077-0.376-2.5-2.5-20.62-3.625-30.868-2.625-31.993 28.619-103.353 79.358-158.591 35.992-39.117 72.86-60.737 103.728-60.737 7.498 0 15.621 0.125 22.995 0.375 31.243 1.374 78.483 3.374 113.6-31.743 21.62-21.62 33.618-50.365 33.493-81.108-0.109-30.744-11.997-59.612-33.727-81.358z" fill="#FFFFFF" p-id="5986"></path></svg> Target Company</div>
              <div class="flex_center  Deletepostion" v-show="!isDelOne" style="color: #F56B6B;" @click="(addIndex = 2), delCompany()">
              <!-- <i class="cop_del_ic"></i> -->
              <svg t="1730875902586" style="margin-right: 10px" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6280" width="20" height="20"><path d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z" fill="#1A2332" p-id="6281"></path></svg>
         
            </div>
              <div class="cop_add">
                <div class="flex_center CompanyNameActive" v-show="isDelOne" @click="(addIndex = 2), Add()">
                  <!-- <i class="cop_add_ic"></i> -->
                  <svg t="1725586885335" class="icon" style="margin-right: 10px" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="5401" width="20" height="20">
                    <path
                      d="M995.84 483.84H540.16V28.16a28.16 28.16 0 1 0-56.32 0v455.68H28.16a28.16 28.16 0 0 0 0 56.32h455.68v455.68a28.16 28.16 0 1 0 56.32 0V540.16h455.68a28.16 28.16 0 1 0 0-56.32z"
                      p-id="5402" fill="#1290C9"></path>
                  </svg>
                  Add a Company
                </div>

              </div>
              <div class="line_2" style="font-family: 'Arial Bold';">{{ compObj2.CompanyName }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.CompanyNativeName }}</div>
          </div>
          <div class="item">
            <div class="line_2">
              {{ compObj2.status }}
              <!-- <div class="c_d_tag">
                                  <el-tag v-show="!showTranslate" v-for="(item, index) in compObj2.status" :key="index + item">{{ item }}</el-tag>
                                  <el-tag v-show="showTranslate" v-for="(item, index) in compObj2.status" :key="index">{{ item }}</el-tag>
                              </div> -->
            </div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.LegalForm }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.YearsinBusiness }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.RegCapital }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.isic }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.IndustryDescription }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.Listed }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.NoofEmployees }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.Noofshareholders }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.Revenue }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.Netincome }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.TotalAssets }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.Profitmargin }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.Profitability }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.BlackList }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj2.CreditScore }}</div>
          </div>
          <!-- <div class="item" v-show="lastView">
              <button class="btn_view" @click="ViewDetails(2)">
                View Details
              </button>
              <el-tooltip
                v-if="updateDisabled"
                content="It normally takes 2-4 days to deliver,this updated rerpot."
                :visible-arrow="false"
                placement="top"
                effect="light"
              >
                <button class="btn_gray">In Production...</button>
              </el-tooltip>
              <button class="btn_view" v-else @click="getPDFFile(2)">
                Order Update Report
              </button>
            </div> -->
        </div>
        <div class="list content">
          <div class="item item_active">
            <div class="Deletepostionleft"><svg style="margin:0px 10px;" t="1730875056113" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5985" width="20" height="20"><path d="M990.73312 306.653c-2.624-2.624-254.57-254.32-279.19-278.44-0.875-0.875-1.625-1.625-2.625-2.375-45.74-37.242-111.6-33.868-153.217 7.874-35.242 35.117-33.243 82.232-31.993 112.85 0.125 7.874 0.375 15.997 0.375 23.495 0 32.618-30.744 79.858-78.233 120.35-49.615 42.366-106.728 68.235-139.096 62.986-7.623-1.25-14.622-1.874-20.87-2.374-3-0.25-5.624-0.5-8.248-0.75-152.718-37.867-221.453 62.362-229.451 74.984-11.623 20.246-8.499 45.74 7.873 61.987l166.964 166.964-212.97 288.313c-15.122 20.246-12.997 48.115 5.124 66.361 9.998 9.998 22.995 15.122 35.992 15.122 10.498 0 20.996-3.25 29.869-9.998L372.35012 803.547l164.964 164.965c16.622 16.621 42.366 19.746 62.987 7.248 11.498-7.248 111.476-75.484 74.61-225.077-0.376-2.5-2.5-20.62-3.625-30.868-2.625-31.993 28.619-103.353 79.358-158.591 35.992-39.117 72.86-60.737 103.728-60.737 7.498 0 15.621 0.125 22.995 0.375 31.243 1.374 78.483 3.374 113.6-31.743 21.62-21.62 33.618-50.365 33.493-81.108-0.109-30.744-11.997-59.612-33.727-81.358z" fill="#FFFFFF" p-id="5986"></path></svg> Target Company</div>
            <div class="flex_center Deletepostion" v-show="!isDelTwo" style="color: #F56B6B;" @click="(addIndex = 3), delCompany()">
                <!-- <i class="cop_del_ic"></i> -->
                <svg t="1730875902586" style="margin-right: 10px" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6280" width="20" height="20"><path d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z" fill="#1A2332" p-id="6281"></path></svg>


              </div>
            <div class="cop_add">
              <div class="flex_center CompanyNameActive" v-show="isDelTwo" @click="(addIndex = 3), Add()">
                <svg t="1725586885335" class="icon" style="margin-right: 10px" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="5401" width="20" height="20">
                  <path
                    d="M995.84 483.84H540.16V28.16a28.16 28.16 0 1 0-56.32 0v455.68H28.16a28.16 28.16 0 0 0 0 56.32h455.68v455.68a28.16 28.16 0 1 0 56.32 0V540.16h455.68a28.16 28.16 0 1 0 0-56.32z"
                    p-id="5402" fill="#1290C9"></path>
                </svg>
                Add a Company
              </div>
    
            </div>
            <div class="line_2" style="font-family: 'Arial Bold';">{{ compObj3.CompanyName }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.CompanyNativeName }}</div>
          </div>
          <div class="item">
            <div class="line_2">
              {{ compObj3.status }}
              <!-- <div class="c_d_tag">
                                  <el-tag v-show="!showTranslate" v-for="(item, index) in compObj3.status" :key="index + item">{{ item }}</el-tag>
                                  <el-tag v-show="showTranslate" v-for="(item, index) in compObj3.status" :key="index">{{ item}}</el-tag>
                              </div> -->
            </div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.LegalForm }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.YearsinBusiness }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.RegCapital }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.isic }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.IndustryDescription }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.Listed }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.NoofEmployees }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.Noofshareholders }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.Revenue }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.Netincome }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.TotalAssets }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.Profitmargin }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.Profitability }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.BlackList }}</div>
          </div>
          <div class="item">
            <div class="line_2">{{ compObj3.CreditScore }}</div>
          </div>
          <!-- <div class="item" v-show="lastView3">
              <button class="btn_view" @click="ViewDetails(3)">
                View Details
              </button>
              <el-tooltip
                v-if="updateDisabled3"
                content="It normally takes 2-4 days to deliver,this updated rerpot."
                :visible-arrow="false"
                placement="top"
                effect="light"
              >
                <button class="btn_gray">In Production...</button>
              </el-tooltip>
              <button class="btn_view" v-else @click="getPDFFile(3)">
                Order Update Report
              </button>
            </div> -->
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="addDialog" center :close-on-click-modal="true" custom-class="dialog_normal2"
      :append-to-body="true">
      <div class="detail_cont">
        <div class="dialog_detail_title">Add a Company</div>
        <div class="head_search flex_center" :class="{ search2: seachdoudong }" ref="input">
          <!-- 选择国家 -->
          <div class="search_left" :class="countryImg ? 'on' : ''">
            <div class="country_flag">
              <img :class="countryImg ? 'country_flag_img' : 'none'" :src="countryImg" />
            </div>
            <el-select v-model="countryCode" ref="select" clearable @clear="clearCountry" placeholder="Select Country"
              popper-class="sel_box" @change="selectCountry" no-match-text="No data" no-data-text="No data">
              <div class="search_sel_container">
                <el-tabs tab-position="left" v-model="tabActiveName">
                  <el-tab-pane v-for="(tab, index) in tabCountryList" :key="index">
                    <div slot="label" class="flex_center_between_box">
                      <span class="left_txt">{{ tab.continent.enName }}</span>
                      <span class="right_txt">{{
                        tab.continent.countryNumStr
                      }}</span>
                    </div>
                    <div class="right">
                      <el-option v-for="item in tab.countryList" :key="item.id" :value="item.countryCode"
                        :label="item.nameEn" @click.native="labelClick(item.label)">
                        <div class="right_item flex_box">
                          <div class="img flex_center">
                            <img :src="item.urlBanner" />
                          </div>

                          <div class="rBox">
                            <div class="coun_title">{{ item.nameEn }}</div>
                            <div class="coun_num">{{ item.companyNumStr }}</div>
                          </div>
                        </div>
                      </el-option>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-select>
          </div>
          <div class="search_btn flex_center">
            <svg t="1725588301247" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="6045" width="20" height="20">
              <path
                d="M863.38 825.09L714.17 675.88c48.01-57.12 74.18-128.58 74.18-204.08 0-84.89-33.06-164.7-93.08-224.72C635.24 187.05 555.44 154 470.55 154c-84.89 0-164.7 33.06-224.72 93.08s-93.08 139.83-93.08 224.72 33.06 164.7 93.08 224.72 139.83 93.08 224.72 93.08c76.22 0 148.33-26.67 205.72-75.56l149.08 149.08a26.805 26.805 0 0 0 19.01 7.88c6.88 0 13.76-2.63 19.01-7.88a26.805 26.805 0 0 0 7.88-19.01c0-6.89-2.62-13.77-7.87-19.02zM206.53 471.8c0-145.58 118.44-264.02 264.02-264.02 145.58 0 264.02 118.44 264.02 264.02S616.13 735.82 470.55 735.82c-145.58 0-264.02-118.44-264.02-264.02z"
                fill="#8497AB" p-id="6046"></path>
            </svg>
            <!-- <img src="../../assets/img/head_search_ic.png" @click="searchCompanyByName()" /> -->
          </div>
          <!-- 搜索 -->
          <div class="search_right flex_center">
            <input type="text" placeholder="Enter company name" v-model="searchName" class="search_input"
              @keyup.enter="searchCompanyByName()" />
          </div>
        </div>

        <div class="" style="position: relative; margin-top: 10px">
          <div class="table_box draggable">
            <el-table ref="treeTable" :key="refresh" :data="parentTable" empty-text="No Data" border row-key="aaa_id"
              @selection-change="handleSelectionChange" @select-all="handleSelectAll" style="width: 100%"
              v-loading="parentTableloding">
              <el-table-column type="selection" width="50"></el-table-column>

              <el-table-column label="Company Name" width="360" align="left" show-overflow-tooltip :resizable="false"
                class-name="company company_link ">
                <template slot-scope="scope">
                  <div class="line_1" style="width: 340px">
                    <img :src="scope.row.flag" />
                    <div @click="setid(scope.row)" class="tbl_company line_1">
                      {{ scope.row.company_name_en }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Location" width="200" prop="" align="left" show-overflow-tooltip
                :resizable="false">
                <template slot-scope="scope">
                  <div style="width: 180px" class="line_1">
                    {{ scope.row.city_en }}
                    <span class="tbl_dot" v-show="scope.row.province_en &&
                      scope.row.province_en != ' ' &&
                      scope.row.province_en != '' &&
                      scope.row.city_en != '' &&
                      scope.row.city_en != ' ' &&
                      scope.row.city_en
                      ">,</span>
                    {{ scope.row.province_en }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Industry" prop="isic_en" align="left" show-overflow-tooltip :resizable="false" />
              <el-table-column label="Status" prop="status_en" align="left" show-overflow-tooltip :resizable="false" />
              <el-table-column label="Type" prop="company_class_en" align="left" show-overflow-tooltip
                :resizable="false">
              </el-table-column>

              <el-table-column label="Size" prop="scale_en" show-overflow-tooltip :resizable="false" align="left" />
              <template slot="empty">
                <div style="text-align: left">No Data</div>
              </template>
            </el-table>
            <!-- <div class="page_i_box">
                              <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                              <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                          </div> -->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { searchCompany } from "@/api/searchApi.js";
import {
  getCompanyCompare,
  getCountryCollection,
  getCompanySnap,
  ordersAdd,
} from "@/api/companyApi.js";
import { getPdfAddress, getPdfPurchase, getPdfhk_pdf } from "@/api/userApi.js";
import crypto from "@/utils/crypto";
import { mixins1 } from "@/mixins/index";

export default {
  mixins: [mixins1],

  data() {
    return {
      compObj: {
        CompanyName: 'COSTCO WHOLESALE CORPORATION',
        CompanyNativeName: '',
        LegalStatus: [],
        LegalForm: '',
        YearsinBusiness: '',
        RegCapital: '',
        isic: '',
        IndustryDescription: '',
        Listed: '',
        NoofEmployees: '',
        Noofshareholders: '',
        Revenue: '',
        Netincome: '',
        TotalAssets: '',
        Profitmargin: '',
        Profitability: '',
        BlackList: '',
        CreditScore: '',
      },
      compObj2: {},
      compObj3: {},
      countryVal: '',
      selCountryList: [],
      enName: '',
      addIndex: 1,
      addDialog: false,
      restaurants: [],
      lastView: false,
      lastView3: false,
      isDelOne: true,
      isDelTwo: true,
      addDisabled: true,
      companyValList: [],
      page: {
        pageNo: 1,
        pageSize: 50,
        total: 0,
      },
      pageChild: {
        pageNo: 1,
        pageSize: 50,
        total: 0,
      },
      id3a: '',
      companyCountry: '',
      companyNameEn: '',
      companyNameCn: '',
      id3a3: '',
      companyCountry3: '',
      companyNameEn3: '',
      companyNameCn3: '',
      countryObj: {},
      updateDisabled: false,
      updateDisabled3: false,
      orderStatus: '',
      offlineOrderId: '',
      offlineOrderId3: '',

      seachdoudong: false,
      //国家分类
      tabCountryList: [],
      productloding: false,
      percentageerr: false,
      countryCode1: '',
      countryCode: '',
      countryName: '', //选中国家
      countryImg: '', //选中国家的国旗
      tabActiveName: '', //选择的区域
      isNotice: true, //通知高亮
      searchName: '',
      parentTable: [],
      childTable: [],
      expands: [],
      branchflag: '',
      refresh: 0,
      parentTableloding: true,
      ret: false,
    };
  },
  computed: {
    //index传值 是否翻译
    showTranslate: {
      get() {
        return this.$parent.indexIsTranslate;
      },
      set() { },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    CompareShow() {
      this.$emit('CompareShowfalse', false)
    },
    handleSelectAll(selection) {
      if (selection.length > 1) {
        this.$refs.treeTable.clearSelection();
        this.$refs.treeTable.toggleRowSelection(selection[0]);
      }
    },
    // 全选
    handleSelectionChange(selection) {
      if (selection.length > 0) {
        this.handleSelect(selection[0]);
      }
    },
    // 国旗跳转
    gotocountry(row) {
      let routeData = this.$router.resolve({
        path: '/country?' + '&companyCountry=' + row.company_country,
      });

      window.open(routeData.href, '_blank');
    },
    // 点击跳转
    setid(row) {
        sessionStorage.setItem("company", JSON.stringify(row));
        let newscountry = JSON.parse(localStorage.getItem("countryList"))[0].countryList
          ;
        let companyCountryEn = "";
        newscountry.map((item) => {
          if (item.countryCode == row.company_country) {
            companyCountryEn = item.dowjonesCountrycode;
          }
        });
        let routeData = this.$router.resolve({
          path:
            "/index/company/detals/" +
            row.aaa_id +
            "/background/snapshot?id3a=" +
            row.aaa_id +
            "&companyCountry=" +
            row.company_country +
            "&companyCountryEn=" +
            companyCountryEn,
        });

        window.open(routeData.href, "_blank");

    },
    //分页
    onPageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.searchCompanyByName();
    },
    currtentPageChange(pageSize) {
      this.page.pageSize = pageSize;
      this.searchCompanyByName();
    },

    //清除选择的国家
    clearCountry() {
      this.countryImg = '';
      this.countryCode = '';
      this.countryCode1 = '';
    },

    //下拉选择国家
    selectCountry(val) {
      let countryImg = this.getCountryImgUrl(val);
      let flag = require('../../../../static' + countryImg);
      this.countryImg = flag;
      this.countryCode = val;

      let newscountry = this.tabCountryList[0].countryList;
      newscountry.map(item => {
        if (item.countryCode == val) {
          this.countryCode1 = item.dowjonesCountrycode;
        }
      });
    },
    // 选中国家
    labelClick(label) { },
    async getData() {
      const id3a = this.$route.query.id3a ? this.$route.query.id3a : '';
      const companyCountry = this.$route.query.companyCountry ? this.$route.query.companyCountry : '';
      let result = await getCompanyCompare('id3a=' + id3a + '&companyCountry=' + companyCountry);
      //解密
      // let data = JSON.parse(crypto.decrypt(result));
      let data = result;
      if (data && data.code) {
        let res = data.data;
        let status = res.status;
        this.compObj = {
          CompanyName: res.nameEn,
          CompanyNativeName: res.name,
          LegalStatus: res.lables,
          // Company Legal Status
          LegalForm: res.legalForm,
          YearsinBusiness: res.yearsInBusiness,
          RegCapital: res.regCapital,
          isic: res.isic,
          IndustryDescription: res.industryDescription,
          Listed: res.listed,
          NoofEmployees: res.noOfEmployees,
          Noofshareholders: res.noOfShareholders,
          Revenue: res.revenue,
          Netincome: res.netIncome,
          TotalAssets: res.totalAssets,
          status: res.status,
          Profitmargin: res.profitMargin,
          Profitability: res.profitability,
          BlackList: res.blackList,
          CreditScore: res.creditScore,
        };
      }
    },
    //搜索公司
    async searchCompanyByName() {
      this.parentTableloding = true;
      let params = {
        company_name: this.searchName,
        company_country: this.countryCode,
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      let res = await searchCompany(params);
      this.parentTable = [];

      if (res && res.code) {
        this.page.total = res.data.total;
        let list = (this.parentTable = res.data.rows);
        //查找本地图片
        list = list.map((item, index) => {
          let countryImg = this.getCountryImgUrl(item.company_country);
          let flag = require("../../../../static" + countryImg);
          this.parentTable[index].flag = flag;
          this.refresh++;
        });
        this.parentTableloding = false;
      } else {
        this.loading = false;
        // this.addPopIndex2=true
        this.parentTable = [];
        this.parentTableloding = false;
        if (res.ret == '1002') {
          this.ret = true;
        } else {
          this.ret = false;
        }
      }
      // }
    },
    //获取国家
    async getAllCountry() {
      let countryCode = (this.countryCode = this.$route.query.companyCountry
        ? this.$route.query.companyCountry
        : "");
      // localStorage.setItem('countryList',JSON.stringify(countryImgList))
      // 处理缓存问题
      if (JSON.parse(localStorage.getItem("countryList"))) {
        this.tabCountryList = JSON.parse(localStorage.getItem("countryList"));
        let list = this.tabCountryList;
        list.forEach((item, index) => {
          item.countryList.forEach((it) => {
            let countryImg = "";
            if (it.urlBanner == null) {

              countryImg = "../../../../static/imagev2/zanwutupian.png";
            } else {

              countryImg = it.urlBanner.split("./../../../static")[1];
            }
            // let flag = require('../../static' + countryImg);
            let flag = "";
            try {
              flag = require("../../../../static" + countryImg);
            } catch (error) {
              countryImg = "../../../../static/imagev2/zanwutupian.png";
              // 提供一个默认值或者其他处理方式
              flag = require("../../../../static" + countryImg);
            }
            it.urlBanner = flag;
            if (countryCode && it.countryCode == countryCode) {
              this.countryName = it.nameEn;
              this.countryImg = it.urlBanner;
            }
          });
        });
      } else {
        // 清理缓存后重新请求页面
        let res = await getCountryCollection();
        if (res && res.code) {
          let countryImgList = res.data.rows;
          this.$store.state.countryImgList = JSON.parse(
            localStorage.getItem("countryList")
          ); //res.data.rows
          this.tabCountryList =
            JSON.parse(localStorage.getItem("countryList")) == null
              ? res.data.rows
              : JSON.parse(localStorage.getItem("countryList"));

          localStorage.setItem("countryList", JSON.stringify(countryImgList));
          let list = this.tabCountryList;

          list.forEach((item, index) => {
            item.countryList.forEach((it) => {
              let countryImg = "";
              if (it.urlBanner == null) {

                countryImg = "../../../../static/imagev2/zanwutupian.png";
              } else {

                countryImg = it.urlBanner.split("./../../../static")[1];
              }
              // let flag = require('../../static' + countryImg);
              let flag = "";
              try {
                flag = require("../../../../static" + countryImg);
              } catch (error) {
                countryImg = "../../../../static/imagev2/zanwutupian.png";
                // 提供一个默认值或者其他处理方式
                flag = require("../../../../static" + countryImg);
              }
              it.urlBanner = flag;
              if (countryCode && it.countryCode == countryCode) {
                this.countryName = it.nameEn;
                this.countryImg = it.urlBanner;
              }
            });
          });
        }
      }
    },

    //add compant弹框
    Add() {
      this.getAllCountry();
      this.countryImg = '';
      this.countryVal = '';
      this.searchName = '';
      this.addDialog = true;
      // this.parentTable=[]
      this.searchCompanyByName();
    },
    // 确定提交
    async submitAdd() {
      let id3a = this.addIndex == 2 ? this.id3a : this.id3a3;
      let companyCountry = this.addIndex == 2 ? this.companyCountry : this.companyCountry3;
      let params = 'aaaId=' + id3a;
      let that = this;
      // getPdfPurchase(params).then(res => {
      //     // if (res.code) {
      //     let repordshow = res.data?.onlineReport?.type;
      //     if (repordshow != 1) {
      //         let type = that.addIndex;
      //         this.getOrdersAdd(type, 1).then(res => {
      //             if (res) {
      //                 this.geitadddate(id3a, companyCountry);
      //             }
      //         });
      //     } else {
      //         this.geitadddate(id3a, companyCountry);
      //     }
      //     // }
      // });
      this.geitadddate(id3a, companyCountry)
    },
    async geitadddate(id3a, companyCountry) {
      let CompanyName = this.addIndex == 2 ? this.companyNameEn : this.companyNameEn3;
      let CompanyNativeName = this.addIndex == 2 ? this.companyNameCn : this.companyNameCn3;
      let result = await getCompanyCompare('id3a=' + id3a + '&companyCountry=' + companyCountry);
      //解密
      // let data = JSON.parse(crypto.decrypt(result));
      let data = result;
      if (data && data.code) {
        let res = data.data;
        let obj = {
          CompanyName: CompanyName,
          CompanyNativeName: CompanyNativeName,
          LegalStatus: res.lables,
          LegalForm: res.legalForm,
          YearsinBusiness: res.yearsInBusiness,
          RegCapital: res.regCapital,
          isic: res.isic,
          IndustryDescription: res.industryDescription,
          Listed: res.listed,
          NoofEmployees: res.noOfEmployees,
          Noofshareholders: res.noOfShareholders,
          Revenue: res.revenue,
          Netincome: res.netIncome,
          TotalAssets: res.totalAssets,
          Profitmargin: res.profitMargin,
          Profitability: res.profitability,
          BlackList: res.blackList,
          status: res.status,
          CreditScore: res.creditScore,
        };
        if (this.addIndex == 2) {
          this.compObj2 = obj;
          this.isDelOne = false;
          this.lastView = true;
          //查看PDF状态
          // this.hasPdfPurchase(2);
        } else {
          this.compObj3 = obj;
          this.isDelTwo = false;
          this.lastView3 = true;

          //查看PDF状态
          // this.hasPdfPurchase(3);
        }
        this.addDialog = false;
      }
    },

    handleSelect(item) {
      this.searchName = item.company_name_en;
      this.addDisabled = false;

      this.countryObj = item;
      if (this.addIndex == 2) {
        this.id3a = item.aaa_id;
        this.companyCountry = item.company_country;
        this.companyNameCn = item.company_name;
        this.companyNameEn = item.company_name_en;
      } else {
        this.id3a3 = item.aaa_id;
        this.companyCountry3 = item.company_country;
        this.companyNameCn3 = item.company_name;
        this.companyNameEn3 = item.company_name_en;
      }
      this.submitAdd();
    },
    //删除添加
    delCompany() {
      if (this.addIndex == 2) {
        this.compObj2 = {};
        this.isDelOne = true;
        this.lastView = false;
        this.parentTable = [];
      } else {
        this.compObj3 = {};
        this.isDelTwo = true;
        this.lastView3 = false;
        this.parentTable = [];
      }
    },

    //  跳转公司详情
    ViewDetails(val) {
      let id3a = val == 2 ? this.id3a : this.id3a3;
      let companyCountry = val == 2 ? this.companyCountry : this.companyCountry3;
      let newscountry = JSON.parse(localStorage.getItem("countryList"))[0].countryList
      let companyCountryEn = "";
      newscountry.map((item) => {
        if (item.countryCode == companyCountry) {
          companyCountryEn = item.dowjonesCountrycode;
        }
      });
      let routeData = this.$router.resolve({
        path:
          "/index/company/detals/" +
          id3a +
          "/background/snapshot?id3a=" +
          id3a +
          "&companyCountry=" +
          companyCountry +
          "&companyCountryEn=" +
          companyCountryEn,
      });
      window.open(routeData.href, '_blank');
    },

    //step-1  获取 判断是否要扣点 线上报告,线下报告
    getPDFFile(val) {
      //有订单号吗
      if (!this.offlineOrderId) {
        let type = 2;
        this.getOrdersAdd(val, type).then(res => {
          if (res) this.hanldPdfPurchase();
        });
      } else {
        this.hanldPdfPurchase();
      }
    },
    //step-3   1.查找订单；2.下载PDF
    async hanldPdfPurchase() {
      if (this.offlineOrderId) {
        await getPdfAddress(this.offlineOrderId).then(res => {
          if (res && res.code) {
            window.open(res.data, '_blank');
          } else {
            this.$message({
              message: 'It normally takes 2-4 days to deliver,this updated rerpot.',
              duration: 5000,
            });
          }
        });
      }
    },
    //step-2 是否购买PDF,获取订单号
    async hasPdfPurchase(val) {
      let id3a = val == 2 ? this.id3a : this.id3a3;
      let params = 'aaaId=' + id3a;
      await getPdfPurchase(params).then(async res => {
        if (res && res.code) {
          if (res.data.offlineReport) {
            this.offlineOrderId = res.data.offlineReport.id;
            this.orderStatus = res.data.offlineReport.orderStatus;
            if (this.orderStatus == '4') {
              if (val == 2) {
                this.updateDisabled = true;
              } else {
                this.updateDisabled3 = true;
              }
            } else {
              if (val == 2) {
                this.updateDisabled = false;
              } else {
                this.updateDisabled3 = false;
              }
            }
          }
        } else {
          if (val == 2) {
            this.updateDisabled = false;
          } else {
            this.updateDisabled3 = false;
          }
        }
      });
    },
    /**
     *用户购买报告 "type": 1 线下，2 线上, 13 Import/Export
     */
    async getOrdersAdd(val, type) {
      let id3a = val == 2 ? this.id3a : this.id3a3;
      let companyCountry = val == 2 ? this.companyCountry : this.companyCountry3;
      let companyName = val == 2 ? this.companyNameEn : this.companyNameEn3;
      //1  :线上报告
      let params = {
        companyName: companyName, //必须
        aaaId: id3a, //必须
        companyCountry: companyCountry, //必须
        method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
        methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
        type: type, //订单类型 //必须
      };
      let status = '';

      await ordersAdd(params).then(result => {
        let data = result;
        if (data && data.code) {
          // this.hasPdfPurchase(val);
          status = true;
          this.$message({
            message: data.msg,
            type: 'success',
            // duration: 5000,
          });
        } else {
          this.$message({
            message: data.msg,
            type: 'error',
            // duration: 5000,
          });
          let that = this;
          let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

          let routeData = '';
          setTimeout(function () {
            if (userinfo.type == '3') {
              routeData = that.$router.resolve({
                path: '/account/quota',
              });
            } else {
              routeData = that.$router.resolve({
                path: '/account/addCredit',
              });
            }

            window.open(routeData.href, '_blank');
          }, 2000);

          status = false;
        }
      });
      return status;
    },

    // // 清除😕选择公司
    // cancelAdd() {
    //     this.addDialog = false;
    //     this.countryImg = '';
    //     this.countryVal = '';
    //     this.enName = '';
    //     this.restaurants = [];
    // },
    // async serchName() {
    //     var list = [];
    //     this.$refs.autocomplete.suggestions = [];
    //     let params = {
    //         company_name: this.enName,
    //         company_country: this.countryVal,
    //         page: this.page.pageNo,
    //         pageSize: this.page.pageSize,
    //     };
    //     let res = await searchCompany(params);
    //     if (res && res.code) {
    //         this.page.total = res.data.total;
    //         this.restaurants = res.data.rows;
    //         for (let i of this.restaurants) {
    //             i.value = i.company_name_en; //将想要展示的数据作为value
    //         }
    //         list = this.restaurants;
    //         this.$refs.autocomplete.suggestions = list;
    //     }
    // },
    //queryString 为在框中输入的值
    //         async querySearch(queryString, callback) {
    //     var list = [];
    //     this.$refs.autocomplete.suggestions = [];
    //     let params = {
    //         company_name: this.enName,
    //         company_country: this.countryVal,
    //         page: this.page.pageNo,
    //         pageSize: this.page.pageSize,
    //     };
    //     let res = await searchCompany(params);
    //     if (res && res.code) {
    //         this.page.total = res.data.total;
    //         this.restaurants = res.data.rows;
    //         for (let i of this.restaurants) {
    //             i.value = i.company_name_en; //将想要展示的数据作为value
    //         }
    //         list = this.restaurants;
    //         callback(list);
    //     } else {
    //         this.restaurants = [];
    //         list = [];
    //         callback(list);
    //     }
    // },
  },
};
</script>
<style scoped lang="less">
.sel_coun {
  position: relative;
}

.country_img {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 0.2857142857142857rem;
  z-index: 8;
}

.country_img img {
  width: 3.2857142857142856rem;
  height: 2.2857142857142856rem;
}

.el-select,
.el-autocomplete {
  width: 100%;
}

.create_item /deep/ .el-input__inner {
  width: 100%;
  height: 2.857142857142857rem;
  border-radius: 0.5714285714285714rem;
  border: 0.07142857142857142rem solid #8497ab;
}

.country_sel /deep/ .el-input__inner {
  padding-left: 3.9285714285714284rem;
}

.create_item /deep/ .el-autocomplete .el-input__inner {
  // background-image: url(../../assets/img/head_search_ic.png);
  background-size: 1.4285714285714286rem;
  background-repeat: no-repeat;
  background-position: 98%;
  padding-right: 4.285714285714286rem;
}

.create_item {
  margin-bottom: 1.4285714285714286rem;
}

.cop_title {
  display: flex;
  align-items: center;
  font-size: 1.4285714285714286rem;
  font-family: 'DMSans Bold';
  padding-bottom: 2.142857142857143rem;
  padding-top: 1.4285714285714286rem;
  background-color: #fff;
}

.cop_cont_head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.857142857142857rem;
  background: #f2f5f9;
  // border-radius: 1rem 1rem 0rem 0rem;
  // border: 0.07142857142857142rem solid #e7eaed;
  // background: #f2f5f9;
  color: #8497ab;
  font-size: 1.2857142857142858rem;
}

.cop_company {
  width: 26%;
  // width: 41.285714285714285rem;
  line-height: 2.7142857142857144rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  // padding-left: 28.571428571428573rem;
}

.cop_companyfirst {
  width: 23.5%;
}

.cop_add {
  // width: 18.857142857142858rem;
  // width: 25%;
  height: 2.857142857142857rem;
  cursor: pointer;
  position: absolute;
}

.cop_add .flex_center {
  height: 2.857142857142857rem;
}

.cop_add_ic {
  background: #1290c9;
  // background-image: url(../../assets/img/detail/add_ic.png);
}

.cop_del_ic {
  background: #f56b6b;
  // background-image: url(../../assets/img/detail/del_ic.png);
}

.cop_del_ic,
.cop_add_ic {
  width: 1.5714285714285714rem;
  height: 1.5714285714285714rem;
  background-repeat: no-repeat;
  background-size: 0.7142857142857143rem;
  background-position: center;
  border-radius: 50%;
  margin-right: 0.5714285714285714rem;
}

.cop_cont_box {
  position: relative;
  background: #ffffff;
}

.cop_cont_cont .item {
  display: flex;
  align-items: center;
  padding: 0.5714285714285714rem;
  font-size: 1.2857142857142858rem;
  height: 4.571428571428571rem;
  line-height: 1.7142857142857142rem;
  border-bottom: 0.07142857142857142rem solid #e7eaed;
}

.cop_cont_cont .list.title .item {
  // width: 20rem;
  overflow: hidden;
  padding: 0.5714285714285714rem 1.4285714285714286rem;
  color: #8497ab;
}

.cop_cont_cont .list.content {
  // width: 21.428571428571427rem;
  width: 27%;
  // border-left: 0.07142857142857142rem solid #e7eaed;
}

.cop_cont_cont .list.content.last {
  width: 19rem;
}

.line_2 .c_d_tag {
  width: 17.5rem;
}

.c_d_tag .el-tag {
  height: 1.8571428571428572rem;
  line-height: 1.8571428571428572rem;
  background: #ffffff;
  border-radius: 0.5714285714285714rem;
  border: 0.07142857142857142rem solid #ff7600;
  font-size: 0.8571428571428571rem;
  color: #ff7600;
  margin: 0 0.2857142857142857rem 0.14285714285714285rem;
}

.btn_box {
  margin-top: 2.142857142857143rem;
}

.btn_return {
  width: 10rem;
  height: 2.857142857142857rem;
  line-height: 2.857142857142857rem;
  background: #e7eaed;
  border-radius: 0.8571428571428571rem;
  font-size: 1.1428571428571428rem;
  color: #022955;
}

.create_item_lab {
  font-size: 1.1428571428571428rem;
  color: #022955;
  margin-bottom: 0.5714285714285714rem;
}

.create_inpt {
  width: 100%;
  height: 3.2857142857142856rem;
  background: #ffffff;
  border-radius: 0.8571428571428571rem;
  border: 0.07142857142857142rem solid #e7eaed;
  font-size: 1.1428571428571428rem;
  padding: 0.9285714285714286rem 1.4285714285714286rem;
  margin-bottom: 1.4285714285714286rem;
}

.btn_view {
  padding: 0.5714285714285714rem 0.42857142857142855rem;
  background: #edf9ff;
  border-radius: 0.8571428571428571rem;
  border: 0.07142857142857142rem solid #1290c9;
  color: #1290c9;
  height: 2.4285714285714284rem;
  line-height: 1.2857142857142858rem;
  font-size: 1rem;
}

.btn_view+.btn_view {
  margin-left: 0.5714285714285714rem;
}

.name_ser {
  position: relative;
}

.ser_icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 4.285714285714286rem;
  height: 2.857142857142857rem;
}

.btn_gray,
.btn_blue {
  width: 10rem;
  height: 2.4285714285714284rem;
  line-height: 2.4285714285714284rem;
  background: #1290c9;
  border-radius: 0.8571428571428571rem;
  font-size: 1rem;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}

.btn_gray,
.btn_blue:disabled {
  color: #8497ab;
  background: #e7eaed;
}

.btn_gray {
  margin-left: 0.7142857142857143rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}

// .right_box/deep/ .el-dialog__wrapper {
//     overflow: visible;
//     z-index: 2004 !important;
//     position: fixed;
//     top: 9.357142857142858rem;
//     right: 0;
//     bottom: 0;
//     left: 0;
// }

.el-radio__inner {
  width: 1.4285714285714286rem;
  height: 1.4285714285714286rem;
}

/* 搜索 */
.head_search {
  // width: 67.85714285714286rem;
  height: 3.857142857142857rem;
  background: #ffffff;
  // border-radius: 1rem;
  border: 0.07142857142857142rem solid #cccccc;
  color: #022955;
  // margin: auto;
  // margin-left: 4.428571428571429rem;
}

.search_right {
  flex: 1;
  position: relative;
}

.search_input {
  width: 100%;
  word-wrap: break-word;
  line-height: 2.142857142857143rem;
  outline: none;
  border: none;
  // padding: 0 1.4285714285714286rem;
  font-size: 1rem;
}

.search_btn {
  width: 2.857142857142857rem;
  height: 3.857142857142857rem;
  cursor: pointer;
}

.search_btn img {
  width: 1.7142857142857142rem;
}

/* 选择下拉 */
.search_left {
  width: 17.142857142857142rem;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.search_left::after {
  content: "";
  position: absolute;
  right: 0;
  top: 1.2142857142857142rem;
  width: 0.07142857142857142rem;
  height: 1.4285714285714286rem;
  background-color: #e7eaed;
}

.search_left /deep/ .el-input__inner {
  border: none;
  font-size: 1.1428571428571428rem;
}

/deep/ .el-input__inner {
  padding-right: 0rem !important;
}

.country_flag {
  display: flex;
  align-items: center;
}

.country_flag_img {
  width: 2.5714285714285716rem;
  height: 1.5714285714285714rem;
  margin: 1.0714285714285714rem 0.14285714285714285rem 1.0714285714285714rem 1.0714285714285714rem;
  box-shadow: 0rem 0.07142857142857142rem 0.14285714285714285rem 0rem rgba(0, 0, 0, 0.2);
}

.sel_value {
  width: 4.571428571428571rem;
  font-size: 1rem;
  word-break: break-all;
}

.search_left .el-select {
  position: absolute;
  top: 0;
  width: 17.428571428571427rem;
}

.search_left /deep/ .el-select .el-input__inner {
  background-color: transparent;
  height: 3.7142857142857144rem;
  /* opacity: 0; */
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_left.on /deep/ .el-select .el-input__inner {
  padding-left: 4.285714285714286rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.head_pro_cas /deep/ .el-input__inner::placeholder,
.search_left /deep/ .el-select .el-input__inner::placeholder {
  color: #022955;
}

.head_pro_cas /deep/ .el-icon-arrow-down:before,
.search_left /deep/ .el-select .el-icon-arrow-up:before {
  color: #8497ab !important;
  // font-weight: 600;
}

.sel_place {
  width: 100%;
  line-height: 3.7142857142857144rem;
  padding-left: 1.4285714285714286rem;
  display: inline-block;
  font-size: 1rem;
}

.el-select-dropdown__wrap {
  max-height: 26.785714285714285rem !important;
}

/* .el-cascader__dropdown{
  z-index: 9999999 !important;
  } */
.el-select-dropdown__list {
  padding: 0.7142857142857143rem 0 !important;
}

.search_sel_container {
  width: 85.71428571428571rem;
}

.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
  width: 17.142857142857142rem;
}

.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
  width: 0.07142857142857142rem;
  background-color: #eee;
}

.head_swi_keyword {
  position: relative;
}

.head_swi_keyword .el-switch,
.head_login .el-switch {
  height: 2.4285714285714284rem;
  line-height: 2.4285714285714284rem;
}

.head_swi_keyword /deep/ .el-switch__core,
.head_login /deep/ .el-switch__core {
  width: 7rem !important;
  height: 2.4285714285714284rem;
  background: #006a9a;
  box-shadow: inset 0rem 0.07142857142857142rem 0.14285714285714285rem 0rem #054562;
  border-radius: 1.2142857142857142rem;
  border: none;
}

.head_swi_keyword /deep/ .el-switch__core {
  width: 7.142857142857143rem !important;
}

.head_swi_keyword /deep/ .el-switch.is-checked .el-switch__core {
  background: #ff7600;
  box-shadow: inset 0rem 0.07142857142857142rem 0.14285714285714285rem 0rem #aa4f00;
}

.head_swi_keyword /deep/ .el-switch__core:after,
.head_login /deep/ .el-switch__core:after {
  top: 0.21428571428571427rem;
  left: 0.21428571428571427rem;
  width: 2rem;
  height: 2rem;
  background: #ffffff;
}

.head_swi_keyword /deep/ .el-switch.is-checked .el-switch__core::after {
  left: 6.142857142857143rem;
}

.search_sel_container /deep/ .el-tabs__active-bar {
  display: none;
}

.search_sel_container .right {
  width: 68.5rem;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  max-height: 24.285714285714285rem;
  align-content: flex-start;
  margin-right: 0.07142857142857142rem;
  padding: 0 0.8571428571428571rem;
  overflow: auto;
}

.search_sel_container /deep/ .el-tabs--left .el-tabs__item.is-left {
  height: 3rem;
  line-height: 3rem;
  margin-bottom: 0.5714285714285714rem;
  padding: 0 0.7142857142857143rem !important;
}

.search_sel_container /deep/ .el-tabs__item.is-left.is-active {
  background: #F5F5F5;
}

.el-select-dropdown__item:hover {
  background: #F5F5F5;
}

.search_sel_container /deep/ .el-tabs__item:hover {
  color: #1290c9;
  font-weight: normal;
}

.search_sel_container .left_txt {
  font-size: 1.1428571428571428rem;
}



.search_sel_container .right .el-select-dropdown__item {
  width: 15.857142857142858rem;
  height: 4rem;
  padding: 0 0.7142857142857143rem;
  margin: 0.3542857142857143rem 0.35714285714285715rem;
  border: 0.07142857142857142rem solid #fff;
  background: #F5F5F5
}

.search_sel_container .right /deep/ .el-select-dropdown__item.selected,
.search_sel_container .right /deep/ .el-select-dropdown__item.hover {
  background: #F5F5F5;
  /* border-radius: 1rem; */
  border: 1px solid #1290c9;
}

.search_sel_container .right /deep/ .el-select-dropdown__item.selected {
  color: #022955;
  font-weight: normal;
}

.right_item {
  height: 100%;
  width: 100%;
}

.search_sel_container .right .img {
  width: 3.857142857142857rem;
  height: 100%;
  margin-right: 0.35714285714285715rem;
}



.search_sel_container .right .rBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search_sel_container .right .rBox .coun_title {
  font-size: 1.1428571428571428rem;
  line-height: 1;
  margin-bottom: 0.2857142857142857rem;
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



.table_box /deep/ .el-table__empty-block .el-table__empty-text {
  width: auto;
  /* justify-content: flex-start !important; */
}

.list-title {
  width: 25%;
}

.CompanyNameActive {
  font-size: 1.2857142857142858rem;
  color: #1290c9 !important;
}

.el-select /deep/ .el-input__suffix {
  right: 1rem !important;
}

.content {
  padding: 0rem;
  margin: 0rem !important;
}

.flex_center {
  justify-content: flex-start;
}

.contentone .item {
  padding-left: 1.4285714285714286rem !important;
}

.item_active {
  background: #f2f5f9;
  height: 10rem !important;
  position: relative;
}
.Deletepostion{
  position: absolute;
  top: 0.7142857142857143rem;
  right: 0.7142857142857143rem;
}
.Deletepostionleft{
  position: absolute;
  top:0rem;
left: 0.7142857142857143rem;
  width: 14.285714285714286rem;
height: 2.857142857142857rem;
background: #A6B7C9;
border-radius: 0rem 0rem 1.4285714285714286rem 0rem;
font-size: 1.2857142857142858rem;
color: #FFFFFF;
line-height: 2.857142857142857rem;
display: flex;
align-items: center;

}
</style>